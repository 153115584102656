import { Button, type Color, type Size, type Variant } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import dynamic from 'next/dynamic';
import { Fragment, ReactElement, useState } from 'react';

import { NormalBriefingProps } from '_components/Briefing/versions/NormalBriefing/types';
import { useNavigationContext } from '_core/context/navigation-context';
import { usePublicAppContext } from '_core/context/public-app-context';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { briefingConfig } from '_features/briefing/briefing.config';
import { BriefingScreenContent } from '_types/generic';

const NormalBriefing = dynamic<NormalBriefingProps>(
  () => import('_components/Briefing/versions/NormalBriefing').then((mod) => mod.NormalBriefing),
  { ssr: false },
);

export type BriefingButtonProps = {
  page?: string;
  cta?: string;
  truncate?: boolean;
  size?: Size;
  buttonStyle?: Color;
  buttonVariant?: Variant;
  className?: string;
  label?: string | ReactElement;
  opened?: boolean;
  isReferral?: boolean;
  dataTestid?: string;
  initialModalContent?: BriefingScreenContent;
};

export const BriefingButton: React.FC<BriefingButtonProps> = (props) => {
  const {
    page,
    cta,
    size,
    buttonStyle,
    buttonVariant,
    className,
    label,
    opened = false,
    isReferral = false,
    dataTestid,
    initialModalContent = BriefingScreenContent.STEPS,
    ...restProps
  } = props;

  const { query } = useNavigationContext();

  const [open, setOpen] = useState(opened);

  const { t } = useTranslation(briefingConfig.i18nNamespaces);
  const { domainInfo, briefingOptions, locale } = usePublicAppContext();
  const { expertise, skills, placeId, address, iso31661 } = briefingOptions || {};
  const currency = domainInfo?.getCurrency() || 'EUR';
  const defaultIso31661 = domainInfo?.getIso31661();
  const { placeId: defaultPlaceId, address: defaultAddress } = domainInfo?.getLocation(locale) ?? {};

  // Next doesn't handle regional locales yet.. The only one we handle at the moment is nl-BE.
  // To make sure the project are posted correctly and handled by the right person, we need this trick.
  const regionalizedLocale = domainInfo?.getIso31661() === 'BE' && locale === 'nl' ? 'nl-BE' : locale;

  const briefingProps = {
    page,
    cta: cta,
    address: address || defaultAddress,
    placeId: placeId || defaultPlaceId,
    expertise: expertise,
    locale: regionalizedLocale || 'en',
    currency: currency || undefined,
    iso31661: iso31661 || defaultIso31661 || undefined,
    skills: skills,
    open: open,
    onClose: () => setOpen(false),
    queryParams: query,
    initialModalContent,
  };

  return (
    <Fragment>
      <Button
        data-testid={dataTestid || 'briefing-button'}
        size={size || 'sm'}
        buttonStyle={buttonStyle || 'primary'}
        buttonVariant={buttonVariant || 'raised'}
        label={label || t('briefing:buttonLabel')}
        onClick={() => {
          setOpen(!open);
        }}
        className={className}
        {...restProps}
      />

      {open && <NormalBriefing {...briefingProps} sources={getSourceObject()} />}
    </Fragment>
  );
};
